export default class MainNav {
	constructor() {
		this.init();
	}

	init() {
		this.toggle = document.getElementById( 'menu-toggle' );
		this.navContainer = document.querySelector( '.site-header__navcontainer' );
		this.events();
	}

	show() {
		this.navContainer.classList.add( '-open' );
		this.toggle.classList.add( '-open' );
	}

	hide() {
		this.navContainer.classList.remove( '-open' );
		this.toggle.classList.remove( '-open' );
	}

	isVisible() {
		return this.toggle.classList.contains( '-open' );
	}

	events() {
		this.toggle.addEventListener( 'click', e => {
			e.preventDefault();

			if ( this.isVisible() ) {
				this.hide();
			} else {
				this.show();
			}

		} );
	}

}




