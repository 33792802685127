import Map from './map';

export class MemberMap {

	constructor( element ) {
		this.element = element;
		this.map = new Map( element.querySelector( '.membermap__map' ) );

		this.renderPoints();
		this.renderNetworks();
	}

	renderNetworks() {
		this.element.querySelectorAll( '.membermap-network' ).forEach( el => {
			this.map.addContent( new google.maps.LatLng( el.dataset.lat, el.dataset.long ), el );
		} );
	}

	renderPoints() {
		/*if ( typeof membermap_points === "undefined" ) {
			return;
		}*/
		if ( !this.element.dataset.points || !window[ this.element.dataset.points ] ) {
			return;
		}

		let points = window[ this.element.dataset.points ];

		points.forEach( ( p ) => {

			switch ( p.type ) {
				case 'marker':
					this.map.addMarker( p.key, p.lat, p.long );
					break;
				case 'dot':
				default:
					this.map.addDot( p.key, p.lat, p.long );
			}

			if ( p.title ) {
				let content = p.title;

				if ( p.link ) {
					content = `<a href="${p.link}">${content}</a>`;
				}

				this.map.addInfoWindow( p.key, `<div class="maps-title">${content}</div>` );
			}
		} );

		points.forEach( ( p ) => {
			if ( p.hasOwnProperty( 'connect' ) ) {
				p.connect.forEach( ( dest ) => {
					this.map.connect( [ p.key, dest ] );
				} );
			}
		} );
	}

}
