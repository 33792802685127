import {MemberMap} from "./maps/membermap";
import MainNav from "./modules/nav";
import MainSearch from "./modules/search";
import LanguageSelector from "./modules/languages";
import SlideMap from "./maps/slidemap";
import Contentslider from "./modules/contentslider";
import ArchiveFilter from "./modules/filter";
import NewsletterSubscribe from "./modules/newsletter-subscribe";

global.initMap = () => {
	document.dispatchEvent( new CustomEvent( 'mapsready' ) );
};

document.addEventListener( "DOMContentLoaded", () => {
	new MainNav();
	new MainSearch();
	new LanguageSelector();

	let archiveFilter = new ArchiveFilter();
	archiveFilter.register();

	let newsletterSubscribe = new NewsletterSubscribe();
	newsletterSubscribe.register();

	document.querySelectorAll( '.contentslider' ).forEach( element => {
		new Contentslider( element );
	} );

	document.querySelectorAll( 'select' ).forEach( element => {
		new SelectBox( element );
	} );

} );

document.addEventListener( 'mapsready', () => {

	document.querySelectorAll( '.membermap' ).forEach( el => {
		new MemberMap( el );
	} );

	document.querySelectorAll( '.contentslide__content__map' ).forEach( el => {
		new SlideMap( el );
	} );

} );

class SelectBox {
	constructor( el ){
		this.element = el;
		this.init();
	}

	init(){
		this.element.addEventListener( 'change', () => {
			if( this.element.value !== '' ){
				this.element.classList.add('-selected');
			} else {
				this.element.classList.remove('-selected');
			}
		});
	}
}