import axios from "axios";

export default class ArchiveFilter {

	register() {
		this.listenToFormChange();
	}

	listenToFormChange() {
		let $filters = document.getElementsByClassName( 'archive-filter' );

		if ( !$filters.length ) {
			return;
		}

		let self = this;
		let $form = $filters.item( 0 );
		let $selectCollection = $form.getElementsByTagName( 'select' );

		for ( let $select of $selectCollection ) {
			$select.onchange = () => {
				let query = self.getNewQuery( $selectCollection )
				let queryString = '?' + new URLSearchParams( query ).toString();

				axios.get( queryString, {
					headers : {
						ajax : true
					}
				} )
					.then( function( response ) {
						window.history.pushState( '', '', response.data.data.url );

						document.getElementById( 'archive-items' ).outerHTML = response.data.data.html;

						self.listenToFormChange();
					} );
			};
		}
	}

	getNewQuery( $selectCollection ) {
		let query = {};

		for ( let $select of $selectCollection ) {
			if ( $select.value ) {
				query[ $select.getAttribute( 'name' ) ] = $select.value;
			}
		}

		return query;
	}

}




