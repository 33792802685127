import axios from "axios";
import serialize from "form-serialize";

export default class NewsletterSubscribe {

	register() {
		this.listenToFormSubmit();
	}

	listenToFormSubmit() {
		let $form = document.getElementById( 'newsletter-subscribe' );

		if ( $form === null ) {
			return;
		}

		$form.onsubmit = ( e ) => {
			e.preventDefault();

			// Remove any previous messages
			let previousMessage = $form.getElementsByTagName( 'p' );

			if ( previousMessage.length ) {
				previousMessage.item( 0 ).remove();
			}

			// Show spinner
			let $spinner = $form.getElementsByClassName( 'form-spinner' ).item( 0 );
			$spinner.style.display = 'inline-block';

			axios.post( '', serialize( $form ), {
				headers : {
					ajax : true
				}
			} )
				.then( function( response ) {
					// Hide spinner
					$spinner.style.display = 'none';

					let messageType = response.data.success ? 'success' : 'error';

					$form.insertAdjacentHTML( 'afterbegin', '<p class="form-message -dark -' + messageType + '">' + response.data.data.message + '</p>' );
				} );
		};

	}

}




