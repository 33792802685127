import gStyle from './style';
import Popup from "./map-popup";
import Dot from "./dot";

export default class Map {

	constructor( element ) {
		this.element = element;
		this.map = this.createMap();
		this.markers = [];
		this.infowindows = [];
		this.helpers = {
			Popup : Popup()
		}
	}

	getMap() {
		return this.map;
	}

	addContent( position, content ) {
		let popup = new this.helpers.Popup( position, content );
		popup.setMap( this.getMap() );
	}

	addDot( name, lat, lng ) {
		let D = new Dot();
		let self = this;
		let marker = new google.maps.Marker( {
			position : new google.maps.LatLng( lat, lng ),
			map : this.map,
			icon : D.small(),
			optimized : false,
		} );

		this.markers[ name ] = marker;

		google.maps.event.addListener( marker, 'mouseover', ( e ) => {
			self.resetDots();
			self.closeInfoWindows();

			marker.setIcon( D.large() );
		} );

		google.maps.event.addListener( marker, 'mouseout', ( e ) => {
			marker.setIcon( D.small() );
		} );
	}

	resetDots() {
		let D = new Dot();

		Object.keys( this.markers ).forEach( el => {
			this.markers[ el ].setIcon( D.small() );
		} );
	}

	openInfoWindow( name, marker ) {
		let D = new Dot();

		this.resetDots();
		this.closeInfoWindows();

		this.infowindows[ name ].open( this.map, marker );

		google.maps.event.addListener( marker, 'mouseout', ( e ) => {
			marker.setIcon( D.large() );
		} );

		marker.setIcon( D.large() );
	}

	addInfoWindow( name, content ) {
		let self = this;
		let marker = this.markers[ name ];
		let infowindow = new google.maps.InfoWindow( {
			content : content
		} );

		google.maps.event.addListener( marker, 'click', function() {
			self.openInfoWindow( name, marker );
		} );

		google.maps.event.addListener( marker, 'mouseover', function() {
			self.openInfoWindow( name, marker );
		} );

		this.infowindows[ name ] = infowindow;
	}

	closeInfoWindows() {
		Object.keys( this.infowindows ).forEach( el => {
			this.infowindows[ el ].close();
		} );

	}

	addMarker( name, lat, lng ) {
		let svg = `<svg version="1.1" viewBox="0 0 28 40" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g transform="translate(-6)" fill="#B0549C"><path d="m20.208 0c7.3698 0 13.333 5.9635 13.333 13.333 0 1.5885-0.18229 3.2292-0.85938 4.6615l-9.4792 20.156c-0.54688 1.1458-1.7448 1.849-2.9948 1.849-1.25 0-2.4479-0.70312-2.9688-1.849l-9.5052-20.156c-0.67708-1.4323-0.85938-3.0729-0.85938-4.6615 0-7.3698 5.9635-13.333 13.333-13.333zm0 6.6667c-3.6719 0-6.6667 2.9948-6.6667 6.6667 0 3.6719 2.9948 6.6667 6.6667 6.6667s6.6667-2.9948 6.6667-6.6667c0-3.6719-2.9948-6.6667-6.6667-6.6667z"/></g></g></svg>`;

		this.markers[ name ] = new google.maps.Marker( {
			position : new google.maps.LatLng( lat, lng ),
			map : this.map,
			icon : { url : 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent( svg ), scaledSize : new google.maps.Size( 20, 20 ) },
			optimized : false,
		} );
	}

	connect( points ) {

		let Endpoints = [];
		points.forEach( p => {
			if ( this.markers.hasOwnProperty( p ) ) {
				Endpoints.push( this.markers[ p ].position );
			}
		} );

		new google.maps.Polyline(
			{
				path : Endpoints,
				strokeColor : "#B0549C",
				strokeOpacity : 1.0,
				strokeWeight : 1,
				map : this.map
			} );
	}

	setCenter( lat, long ) {
		this.map.setCenter( new google.maps.LatLng( lat, long ) );
	}

	createMap() {
		let center = [ 0, 0 ];

		if ( this.element.dataset.center ) {
			center = this.element.dataset.center.split( ',' );
		}

		return new google.maps.Map( this.element, {
			zoom : parseFloat( this.element.dataset.zoom ),
			minZoom : 1,
			styles : gStyle,
			disableDefaultUI : true,
			zoomControl : this.element.dataset.zoom_control,
			center : {
				lat : parseFloat( center[ 0 ] ),
				lng : parseFloat( center[ 1 ] ),
			},
			scrollwheel : false,
			scaleControl : true,
			gestureHandling : "cooperative",
			backgroundColor : 'none'
		} );
	}
}

