export default class Dot {

	constructor() {
		this.svg = `<svg width="4px" height="4px" viewBox="0 0 4 4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><circle id="Oval" fill="#B0549C" cx="2" cy="2" r="2"></circle></g></svg>`;
	}

	getSVG() {
		return this.svg;
	}

	small() {
		return {
			url : 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent( this.getSVG() ),
			scaledSize : new google.maps.Size( 8, 8 ),
			origin : new google.maps.Point( 0, 0 ),
			anchor : new google.maps.Point( 4, 4 ),
		}
	}

	large() {
		return {
			url : 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent( this.getSVG() ),
			scaledSize : new google.maps.Size( 10, 10 ),
			origin : new google.maps.Point( 0, 0 ),
			anchor : new google.maps.Point( 6, 6 ),
		}
	}

}