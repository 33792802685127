import {tns} from 'tiny-slider/src/tiny-slider';

export default class Contentslider {
	constructor( element ) {
		this.element = element;
		this.initSlider();
	}

	initSlider() {
		let contentslider = this.element.querySelector( '[data-slider]' );

		if ( contentslider ) {
			this.slider = tns( {
				container : contentslider,
				items : 1,
				mode:'gallery',
				rewind: true,
				controlsPosition : 'bottom',
				controlsContainer: this.element.querySelector('[data-controls]'),
				nav: false
			} );
		}
	}

}




