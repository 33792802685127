export default class MainSearch {
	constructor() {
		this.element = document.querySelector( '.searchform' );
		this.toggle = this.element.querySelector( 'button' );
		this.input = this.element.querySelector( 'input' );
		this.init();
	}

	isOpen() {
		return this.element.classList.contains( '-open' );
	}

	open() {
		this.element.classList.add( '-open' );
		this.input.focus();
	}

	close() {
		this.element.classList.remove( '-open' );
	}

	submit() {
		this.element.submit();
	}

	init() {
		this.toggle.addEventListener( 'click', e => {
			e.preventDefault();
			if ( this.isOpen() ) {
				if ( this.element.querySelector( 'input' ).value !== '' ) {
					this.element.submit();
				}
			} else {
				this.open();
				this.setCloseEvents();
			}
		} );

		this.element.addEventListener( 'click', e => {
			e.stopPropagation();
		} );

		document.addEventListener( 'click', e => {
			this.close();
		} );
	}

	setCloseEvents() {
		document.addEventListener( 'keyup', e => {
			if ( e.key === 'Escape' ) {
				this.close()
			}
		}, { once : true } );
	}

}



