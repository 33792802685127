import Map from './map';

export default class SlideMap {

	constructor( element ) {
		this.element = element;
		this.map = new Map( element );

		this.map.getMap().setZoom( 1 );

		if ( this.getPosition() ) {
			this.map.addMarker( this.element.dataset.map, this.getPosition().lat(), this.getPosition().lng() );
			this.map.getMap().setCenter( this.getPosition() );
			this.map.getMap().setZoom( parseFloat( this.element.dataset.zoom ) );
		}
	}

	getPosition() {
		let marker = this.element.dataset.mapMarker;

		if ( marker ) {
			let _marker = marker.split( ',' );
			return new google.maps.LatLng( _marker[ 0 ], _marker[ 1 ] );
		}

		return false;
	}

}