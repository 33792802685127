export default [
	{
		"elementType" : "labels",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"elementType" : "labels.icon",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#757575"
			}
		]
	},
	{
		"elementType" : "labels.text.stroke",
		"stylers" : [
			{
				"color" : "#212121"
			}
		]
	},
	{
		"featureType" : "administrative",
		"elementType" : "geometry",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "administrative.country",
		"elementType" : "geometry.stroke",
		"stylers" : [
			{
				"color" : "#060229"
			},
			{
				"visibility" : "on"
			},
			{
				"weight" : 1.5
			}
		]
	},
	{
		"featureType" : "administrative.country",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#9e9e9e"
			}
		]
	},
	{
		"featureType" : "administrative.locality",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#bdbdbd"
			}
		]
	},
	{
		"featureType" : "administrative.neighborhood",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "landscape",
		"elementType" : "geometry.fill",
		"stylers" : [
			{
				"color" : "#0a215f"
			}
		]
	},
	{
		"featureType" : "poi",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "poi",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#757575"
			}
		]
	},
	{
		"featureType" : "poi.park",
		"elementType" : "geometry",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "poi.park",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#616161"
			}
		]
	},
	{
		"featureType" : "poi.park",
		"elementType" : "labels.text.stroke",
		"stylers" : [
			{
				"color" : "#1b1b1b"
			}
		]
	},
	{
		"featureType" : "road",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "road",
		"elementType" : "geometry.fill",
		"stylers" : [
			{
				"color" : "#2c2c2c"
			}
		]
	},
	{
		"featureType" : "road",
		"elementType" : "labels.icon",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "road",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#8a8a8a"
			}
		]
	},
	{
		"featureType" : "road.arterial",
		"elementType" : "geometry",
		"stylers" : [
			{
				"color" : "#373737"
			}
		]
	},
	{
		"featureType" : "road.highway",
		"elementType" : "geometry",
		"stylers" : [
			{
				"color" : "#3c3c3c"
			}
		]
	},
	{
		"featureType" : "road.highway.controlled_access",
		"elementType" : "geometry",
		"stylers" : [
			{
				"color" : "#4e4e4e"
			}
		]
	},
	{
		"featureType" : "road.local",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#616161"
			}
		]
	},
	{
		"featureType" : "transit",
		"stylers" : [
			{
				"visibility" : "off"
			}
		]
	},
	{
		"featureType" : "transit",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#757575"
			}
		]
	},
	{
		"featureType" : "water",
		"elementType" : "geometry",
		"stylers" : [
			{
				"color" : "#0c0334"
			}
		]
	},
	{
		"featureType" : "water",
		"elementType" : "labels.text.fill",
		"stylers" : [
			{
				"color" : "#3d3d3d"
			}
		]
	}
];

//https://maps.googleapis.com/maps/api/staticmap?key=YOUR_API_KEY&center=14.126237516918293,-64.96203738645937&zoom=3&format=png&maptype=roadmap&style=element:labels%7Cvisibility:off&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.country%7Celement:geometry.stroke%7Ccolor:0x060229%7Cvisibility:on%7Cweight:1.5&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:landscape%7Celement:geometry.fill%7Ccolor:0x0a215f&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Cvisibility:off&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Cvisibility:off&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Cvisibility:off&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x070229&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=480x360